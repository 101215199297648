import React from "react";
import GamesData from "../Games.json";
import { useSearchParams } from "react-router-dom";
import DOMPurify from "dompurify";
import Home from "./Home";

export default function Single() {
  const [searchParams] = useSearchParams();
  const slug = searchParams.get("slug");
  const game = GamesData.find((game) => game.slug === slug);

  const createMarkup = (html) => {
    return { __html: DOMPurify.sanitize(html) };
  };

  return (
    <section className="mx-32">
      <h1 className="text-center text-red-500 text-2xl font-bold my-5">
        Title : {game?.title}
      </h1>
      <div className="w-full rounded-lg h-[650px] block p-3  border border-gray-200 shadow shadow-gray-500 hover:bg-gray-100 overflow-hidden my-5">
        <iframe
          src={game?.gameurl}
          className="w-full h-full overflow-hidden"
          title={game?.title}
        ></iframe>
      </div>
      {game.description && (
        <div
          className="p-6  border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 my-3 bg-transparent"
          dangerouslySetInnerHTML={createMarkup(game.description)}
        ></div>
      )}
      {game.sec_desc && (
        <div
          className="p-6  border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 my-3 bg-transparent"
          dangerouslySetInnerHTML={createMarkup(game.sec_desc)}
        ></div>
      )}
      <Home />
    </section>
  );
}
